import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
const title = "About";
const metaDescription = "ScData.io has Star Citizen info and tools";
const metaUrl = "https://scdata.io/about/";
// const metaImage = "https://scdata.io/OgImg/scdata.jpg";
const metaImage = "";
export default () => (
  <Layout>
    <Helmet>
      <title>{`${title} - ScData.io`}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="2445424502355895" />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />
      <meta property="og:image:alt" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@scdata" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      <html lang="en" />
    </Helmet>
    <div id="content" className="container">
      <div className="layoutGrid">
        <div className="card">
          <h1 className="h1">About ScData.io</h1>
          <p>ScData.io is a fan site with Star Citizen info and tools</p>
        </div>
      </div>
    </div>
  </Layout>
);
